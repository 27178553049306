<template>
	<div id="app_entry">
		<jy-container :showLeft="false" canHide v-model="isHide">
			<template slot="left">
				<jy-menu :menu="menu"></jy-menu>
			</template>
			<template>
				<keep-alive v-if="$store.state.info?true:false">
					<router-view />
				</keep-alive>
			</template>
		</jy-container>
	</div>
</template>
<script type="text/javascript">
	import { mapMutations } from "vuex";
	export default {
		data() {
			return {
				isHide: process.env.NODE_ENV !== "production",
			};
		},
		activated() {},
		computed: {
			menu() {
				let menuList = this.$store.state.menuList;
				return menuList;
			},
		},
		watch: {
			isHide: {
				handler: function (b) {
					this.setMenuShow(!b);
				},
				immediate: true,
			},
		},
		methods: {
			...mapMutations(["setMenuShow"]),
			getNav() {
				// console.log(this.$store.state.info)
				let url = "/system/menu/getUserMenu";
				let userId = "";
				if (this.$store.state.info) {
					userId = this.$store.state.info.userId;
				}
				let option = {
					userId,
				};
				this.$http.post(url, option).then(({ detail }) => {
					var analysi, analysiKey;

					detail.forEach((item, Key) => {
						//排班管理
						if (item.menuId == 200) {
							item.children &&
								item.children.forEach((one) => {
									//排班日常管理
									if (one.menuId == 120) {
										one.children = [];
									}
									//排班审核
									if (one.menuId == 130) {
										one.children = [];
									}
								});
						}
						//实时运营信息
						if (item.menuId == 100) {
							item.children = [];
						}
						// 路单管理
						if (item.menuId == 400) {
							item.children &&
								item.children.forEach((one) => {
									//审核
									if (one.menuId == 320) {
										one.children = [];
									}
									//   补录
									if (one.menuId == 310) {
										one.children = [];
									}
								});
						}
						if (item.menuId == 600) {
							let key;
							analysiKey = Key;
							item.children &&
								item.children.forEach((one, k) => {
									if (one.menuId == 510) {
										key = k;
									}
								});
							analysi =
								item.children && item.children.splice(key, 1)[0];
						}
					});

					if (analysi) {
						detail.splice(analysiKey, 0, analysi);
					}

					console.log(detail);

					this.menu = detail;
				});
			},
		},
	};
</script>
<style lang="scss" scope="app_entry">
.app_entry {
	height: 100%;
}
</style>
